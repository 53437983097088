<template>
  <v-row>
    <v-overlay 
      v-if="load"
      :value="true"
      color="white" 
      absolute
      opacity="1"
      style="z-index: 2 !important"  
    >
      <div class="d-flex flex-column justify-center">
        <v-progress-circular
          indeterminate
          size="64"
          color="primary"
          class="mx-auto"      
        ></v-progress-circular>
        <span class="primary--text py-5">Obteniendo Datos</span>
      </div>
    </v-overlay>
    <v-col cols="12">
      <section class="blue-grey lighten-5 rounded-xl">
        <v-row class="py-3">
          <v-col cols="12">
            <span class="primary--text text-h4 font-weight-medium ml-2">Contadores del día</span>
          </v-col>
          <v-col cols="6" sm="3" class="px-md-5">
            <contador
              color="orange lighten-2" 
              icon="mdi-package-variant-closed"
              color-icon="orange" 
              label="Cajas Pre-vendidas"
              :count="contadoresDia.cantidad_cajas"
            />
          </v-col>
          <v-col cols="6" sm="3" class="px-md-5">
            <contador
              color="indigo lighten-1"
              icon="mdi-briefcase-outline"
              color-icon="indigo"
              label="Pedidos"
              :count="contadoresDia.cantidad_pedidos"
            />
          </v-col>
          <v-col cols="6" sm="3" class="px-md-5">
            <contador
              color="light-blue lighten-3"
              icon="mdi-text-box-check-outline"
              color-icon="blue"
              label="Cobros"
              :count="contadoresDia.cobros_realizados"
            />
          </v-col>
          <v-col cols="6" sm="3" class="px-md-5">
            <contador
              color="icono"
              icon="mdi-currency-usd"
              color-icon="teal"
              label="Saldo Cobrado"
              :count="contadoresDia.saldo_cobrado"
            />
          </v-col>
        </v-row>
      </section>
      <section>
        <v-row>
          <v-col cols="12" class="d-flex justify-end">
            <v-card flat tile>
              <v-card-text id="VenCuentasPorCobrar">
                <header-screenshot
                  v-show="capturing"                
                />
                <v-row class="py-3">
                  <v-col
                    cols="12"
                    class="d-flex justify-space-between"
                  >
                  <div class="d-flex flex-column">
                    <span
                      class="primary--text font-weight-bold ml-2"
                      :class="{'text-h4': $vuetify.breakpoint.smAndUp, 'text-h6': $vuetify.breakpoint.xsOnly }"
                    >Consolidado de Cuentas por Cobrar</span>
                    <span
                      v-if="capturing" 
                      class="primary--text font-weight-medium d-flex align-center"
                    >
                      <v-icon size="20" color="primary" class="mr-1">mdi-account</v-icon>
                      Vendedor: <strong>{{ven.nombre}} ({{ven.cod}}) </strong>
                    </span>
                  </div>
                    <v-btn
                      id="btnCapture"
                      color="primary"
                      dark
                      small
                      class="ml-2 py-0"
                      :class="{'px-2': $vuetify.breakpoint.smAndDown}"
                      @click="venCuentasToImage"
                    >               
                      <v-icon 
                        size="22" 
                        :left="$vuetify.breakpoint.mdAndUp" 
                        v-text="'mdi-camera-plus-outline'"
                      />             
                      <span v-if="$vuetify.breakpoint.smAndUp">Capturar</span>          
                    </v-btn>      
                  </v-col>
                <v-col
                    cols="12"
                    sm="6"
                    md="4"
                    class="d-flex flex-column pt-1 justify-end white"
                  >
                    <span class="text-h2 font-weight-black primary--text pb-0 d-flex align-center">
                      <v-icon color="primary" large>mdi-currency-usd</v-icon>
                      {{saldoVencido.Total | FormatCurrency}}
                    </span>
                    <span class="blue-grey--text text--lighten-1 font-italic caption pl-8 mt-n3">Total Cuentas por Cobrar</span>
                    <div v-if="$vuetify.breakpoint.mdAndUp" class="fill-height d-flex align-end white">
                      <v-list dense class="mb-3 white">
                        <v-list-item v-for="(legend,i) in legends" :key="'graficaLegend'+i" class="white">
                          <v-list-item-icon class="mr-1">
                            <v-icon :color="legend.color">mdi-checkbox-blank</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title v-text="legend.text" />
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </div>
                  </v-col>
                  <v-col cols="12" sm="12" md="8" class="px-md-12">
                    <deudas-grafica
                      tipo="bar"
                      :datos="saldoVencido"
                      height="400"
                    />               
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>     
          </v-col>
        </v-row>        
      </section>
    </v-col>
  </v-row>
</template>
<script>
import { screenshot } from '@/util/CaptureData'
import { get } from 'vuex-pathify'
import { getEstadisticaCXC } from '@/services/vendedores.js'

export default {
  name: 'CuentasPorCobrar',
  components: {
    DeudasGrafica: () =>  import(
      /*  webpackChunkName: "deudas-grafica" */
      '@/views/Dashboard/DeudasGrafica.vue'
    ),
    Contador: () => import(
      /* webpackChunkName: "contador" */
      '@/views/Dashboard/Contador.vue'
    ),
  },
  props:{
    ven: {
      type: Object,
      default: () => ({})
    },
  },
  data: () => ({
    capturing: false,
    serie: {
      data: {
        '1 a 30 dias': 14678,
        '31 a 60 dias': 135866.03000000014,
        '61 a 90 dias': 49820.08999999999,
        'Mas de 90 dias': 102433.77999999996,
      }
    },
    load: true,
    saldoVencido: {},
    contadoresDia: {
      cantidad_cajas: 0,
      cantidad_pedidos: 0,
      cobros_realizados: 0,
      saldo_cobrado: 0,
    },
  }),
  computed: {
    venId: get('route/params@cod'),
    legends () {
      const label = this.saldoVencido.data ? Object.keys(this.saldoVencido.data) : []
      const colors = ['#4A148C', '#009688', '#FF5722', '#E91E63', '#FFD600']

      return label.length 
        ? label.map((item, key) => ({
          text: item,
          color: colors[key],
        }))
        : []
    },
    totalSaldoCobrado() {
      var numberFormat = new Intl.NumberFormat('es-ES');
      return this.contadoresDia.saldo_cobrado 
        ? numberFormat.format(this.contadoresDia.saldo_cobrado) 
        : 0
    },
  },
  created() {
    this.getCXC();
  },
  methods: {
    async getCXC () {
      this.load = true
      try {
        const { contadores_por_dia , saldo_vencido } = await getEstadisticaCXC({ id: this.venId })
        this.contadoresDia = {...contadores_por_dia }
        this.saldoVencido = { ...saldo_vencido }    
      } catch (error) {
        this.$root.$showAlert(
          'Lo siento, hubo un error al intentar obtener los datos de Estadística.',
          'error',
        )
      }
      this.load = false
    },
    async venCuentasToImage () {
      this.capturing = true
      const btn = document.getElementById('btnCapture')
      const ID_ELEMENT = 'VenCuentasPorCobrar' 
      const NAME_IMAGE = 'Capture_vendedor_cuentas_por_cobrar'
      try {
        btn.classList.add("d-none")
        // await wait(125)
        await screenshot({ id: ID_ELEMENT, title: NAME_IMAGE, width: this.$vuetify.breakpoint.xsOnly ? 590 : null })
        btn.classList.remove("d-none")
        this.$root.$showAlert(
          'Capture Exitoso, espere la descarga.',
          'success'
        )              
      } catch (error) {
        this.$root.$showAlert(
          'Lo siento, hubo un error al intentar capturar el Reporte.',
          'error',
        )
      } finally {
        this.capturing = false
      }
    },
  },
}
</script>
